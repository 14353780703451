<template>
  <v-container fluid>
    <PageNavigation :items="items" />

    <v-row>
      <v-col cols="12">
        <base-material-card
          :title="$vuetify.lang.t('$vuetify.personList')"
          icon="mdi-account"
        >
          <v-sheet>
            <v-toolbar-items class="d-flex align-center">
              <v-row>
                <v-col cols="12" lg="5" md="3" sm="12">
                  <v-text-field
                    :label="$vuetify.lang.t('$vuetify.search')"
                    v-model="search"
                    append-icon="mdi-magnify"
                  />
                </v-col>

                <v-col cols="12" lg="5" md="6" sm="12">
                  <v-row>
                    <v-col :cols="subDepartments.length <= 1 ? 12 : 6">
                      <v-select
                        :items="departmentsForSelect"
                        item-text="name"
                        item-value="code"
                        :label="$vuetify.lang.t('$vuetify.department')"
                        @change="departmentListChange"
                      />
                    </v-col>
                    <v-col cols="6" v-if="subDepartments.length > 1">
                      <v-select
                        :items="subDepartments"
                        item-text="name"
                        item-value="code"
                        v-model="subDepartments[0]"
                        :label="$vuetify.lang.t('$vuetify.subDepartment')"
                        @change="selectedDeptChange"
                      />
                    </v-col>
                  </v-row>
                </v-col>

                <v-col
                  cols="12"
                  lg="2"
                  md="3"
                  sm="12"
                  class="pr-0 d-flex align-center justify-end"
                >
                  <v-btn color="green" elevation="2" @click="handleDialog">
                    <v-icon left> mdi-account-plus </v-icon>
                    {{ $vuetify.lang.t("$vuetify.add") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-toolbar-items>
          </v-sheet>

          <GeneralTable
            :head="headers"
            :data="filterDatas"
            :search="search"
            v-on:edititem="handleEditDialog"
            v-on:deleteitem="handleDeleteDialog"
          />

          <UserCRUD
            :dialog.sync="dialog"
            @handleData="handleUser"
            :departments="departments"
            :myObj="obj"
            :userList="relationPeopleObject"
            :companiesList="companiesList"
          />

          <DialogDelete
            :dialogDelete.sync="dialogDelete"
            :deleteObj="deleteObj"
            @handleDelete="handleDeleteConfirm"
          />
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  usersList,
  usersDel,
  usersUpsert,
  departmentList,
} from "@/api/company";
import { sellList } from "@/api/basicdata";
import { profilePicUpload } from "@/api/company";

const newObj = () => {
  return {
    sell_company_extend: "",
    sell_company_extends: {},
    id: "",
    name: "",
    mobile: "",
    email: "",
    departments: [],
    relation_people: [],
    role_id: "",
    is_admin: false,
    sell_companys: [],
    employee_id: "", // 员工编码
    id_pass: "", // 身份证护照
    nationality: "", // 国籍
    birthday: "", // 出生日期
    address: "", // 地址
    person_email: "", // 私人邮箱
    facebook: "", // facebook
    telegram: "", // telegram
    wechat: "", // 微信
    line: "", // line
    emergency_contact: "", // 紧急联络人
    emergency_phone: "", // 紧急联络电话
    position: "", // 职位
    entry_time: "", // 入职时间
    probation_salary: "", // 试用薪水
    regular_time: "", // 转正时间
    regular_salary: "", // 转正薪水
    duties: "", // 工作职责
    has_account: false, // 是否开通
    attach: "", // 附件 相关文档 []
    emergency_relationship: "", // 与紧急联系人关系
    base_note: "", // 备注
    entry_note: "", // 备注
    personal_note: "", // 备注
    contact_note: "", // 备注
    name_on_passport: "", // 护照上姓名
    passport: "", // 护照
    avatar: "", // 头像
  };
};

const newTableOption = () => {
  return {
    page: 1,
    itemsPerPage: 10,
    sortBy: [],
    sortDesc: [],
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    totalItems: 0,
    footerProps: {
      itemsPerPageOptions: [10, 20, 30, 40, 50],
    },
  };
};

const newSearch = () => {
  return {
    skip: 0,
    limit: 10,
    key: "",
    department: "",
  };
};

export default {
  components: {
    GeneralTable: () => import("@/components/tableImg"),
    UserCRUD: () => import("@/components/company/UserCRUD"),
    DialogDelete: () => import("@/components/deleteDialog"),
    PageNavigation: () => import("@/components/pageNavigation"),
  },
  data() {
    return {
      companiesList: [],
      dialog: false,
      dialogDelete: false,
      departments: [],
      selectedDept: [],
      selectedSubDept: 0,
      headers: [
        {
          text: this.$vuetify.lang.t("$vuetify.avatar"),
          value: "avatar",
          width: "20",
          filterable: false,
          sortable: false,
          align: "center",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.ID"),
          align: "start",
          sortable: false,
          value: "employee_id",
          align: "center",
        },
        { text: this.$vuetify.lang.t("$vuetify.name"), value: "name" },
        { text: this.$vuetify.lang.t("$vuetify.birthday"), value: "birthday" },
        { text: this.$vuetify.lang.t("$vuetify.email"), value: "email" },
        { text: this.$vuetify.lang.t("$vuetify.mobile"), value: "mobile" },
        { text: this.$vuetify.lang.t("$vuetify.position"), value: "position" },
        {
          text: this.$vuetify.lang.t("$vuetify.opt"),
          value: "actions",
          sortable: false,
          align: "center",
        },
      ],
      users: [],
      items: [
        { text: this.$vuetify.lang.t("$vuetify.home") },
        { text: this.$vuetify.lang.t("$vuetify.company") },
        { text: this.$vuetify.lang.t("$vuetify.user") },
      ],
      search: "",
      obj: newObj(),
      deleteObj: newObj(),
      data_table_options: newTableOption(),
      wait: false,
    };
  },
  methods: {
    getData() {
      usersList({ limit: 1 })
        .then((res) => {
          usersList({ limit: res.total }).then((data) => {
            this.users = [...data.items];
          });
        })
        .catch((err) => console.log(err));
    },
    getDeptList() {
      departmentList()
        .then((data) => {
          this.departments = data.items;
        })
        .catch((err) => console.log(err));
    },
    getCompaniesList() {
      sellList()
        .then((res) => {
          this.companiesList = res.items;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleDeleteDialog(obj) {
      this.dialogDelete = true;
      this.deleteObj = obj;
    },
    handleDeleteConfirm(obj) {
      this.dialogDelete = false;

      usersDel(obj.id)
        .then((response) => {
          if (response.meta === 0) {
            this.$toast.success(this.$vuetify.lang.t("$vuetify.deleteSuccess"));
            this.getData();
          }
        })
        .catch((err) => {
          console.log("Delete Error", err);
          this.$toast.error("Delete Error");
        });
    },
    handleUser(obj, files) {
      this.dialog = false;
      if (obj.relation_people.length) {
        for (let i = 0; i < obj.relation_people.length; i++) {
          obj.relation_people[i] = obj.relation_people[i].user_id;
        }
      }
      obj.relation_people = JSON.stringify(obj.relation_people);
      obj.departments = JSON.stringify(obj.departments);
      obj.sell_companys = JSON.stringify([obj.sell_companys[0].id]);
      if (obj.has_account === false) {
        obj.role_id = "";
        obj.is_admin = false;
        obj.relation_people = "[]";
      }

      usersUpsert(obj)
        .then((res) => {
          if (res.meta === 0) {
            this.getData();
          }
        })
        .catch((err) => console.log(err));
    },
    handleDialog(obj) {
      this.dialog = true;
      this.obj = newObj();

      const a9 = this.companiesList.find(
        (c) => c.id == "5f93a695b83cc357e9cb6773"
      );
      if (a9 != undefined) this.obj.sell_companys.push(a9);
    },
    handleEditDialog(obj) {
      if (this.permissionCheck("modify")) {
        this.dialog = true;
        this.obj = obj;
      }
    },
    departmentListChange(val) {
      this.selectedDept = this.departments.filter((d) => d.code == val);
      this.selectedSubDept = 0;
    },
    selectedDeptChange(val) {
      this.selectedSubDept = val;
    },
  },
  mounted() {
    this.getData();
    this.getDeptList();
    this.getCompaniesList();
  },
  computed: {
    relationPeopleObject: function () {
      const relationPeople = [];
      this.users.forEach((u) => {
        relationPeople.push({ name: u.name, user_id: u.id });
      });
      return relationPeople;
    },
    departmentsForSelect: function () {
      return [{ name: "All", code: 0 }, ...this.departments];
    },
    subDepartments: function () {
      const subD =
        this.selectedDept.length == 1 && this.selectedDept[0].items
          ? this.selectedDept[0].items
          : [];
      return [{ name: "All", code: 0 }, ...subD];
    },
    filterDatas: function () {
      let temp = [];

      if (this.selectedDept.length == 1) {
        let dept = this.selectedDept[0].items || [];
        if (this.selectedSubDept) {
          temp = this.users.filter((u) =>
            u.departments.includes(this.selectedSubDept)
          );
        } else {
          for (let i = 0; i < this.users.length; i++) {
            for (let j = 0; j < dept.length; j++) {
              if (this.users[i].departments.includes(dept[j].code.toString())) {
                temp.push(this.users[i]);
                break;
              }
            }
          }
        }
      } else {
        temp = this.users;
      }

      return temp;
    },
  },
};
</script>